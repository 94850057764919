import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "./Map.css";

// API-Schlüssel von Mapbox
mapboxgl.accessToken = "pk.eyJ1IjoiYWVtbXJhaCIsImEiOiJjbTRlbDlpbjEwc2pxMmpzYXNuMXVseDRpIn0.tE-0ip5k8x3yIshy9xu4FA";

const Map = () => {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    // Karte initialisieren
    const map = new mapboxgl.Map({
      container: mapContainerRef.current, // Container-Ref
      style: "mapbox://styles/mapbox/streets-v11", // Stil der Karte
      center: [13.405, 52.52], // Startkoordinaten [Längengrad, Breitengrad]
      zoom: 10, // Start-Zoomstufe
    });

    // Optional: Marker hinzufügen
    new mapboxgl.Marker().setLngLat([13.405, 52.52]).addTo(map);

    // Cleanup bei Komponentendemontage
    return () => map.remove();
  }, []);

  return <div ref={mapContainerRef} className="map-container" />;
};

export default Map;
