import React, { useEffect, useState } from "react";
import { getBusinesses } from "../api";
import Hero from "../components/Hero";
import { Categories } from "../components/Categories";
import TopRated from "../components/TopRated";
import Map from "../components/Map";
import NewestReviews from "../components/NewestReviews";

const Home = () => {
  const [businesses, setBusinesses] = useState([]);
  const [searchResults, setSearchResults] = useState([]); // Suchergebnisse

  useEffect(() => {
    getBusinesses().then((response) => setBusinesses(response.data));
  }, []);

  return (
    <div>
      <Hero setSearchResults={setSearchResults} />
      {searchResults.length > 0 ? (
        <div className="search-results">
          <h2>Suchergebnisse:</h2>
          <ul>
            {searchResults.map((business) => (
              <li key={business._id}>{business.name}</li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          <Categories limit={4} /> {/* Zeigt nur die ersten 4 Kategorien */}
          <TopRated />
          <Map />
          <NewestReviews />
        </>
      )}
    </div>
  );
};

export default Home;
