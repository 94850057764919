import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Business from "./pages/Business";
import Login from "./pages/Login";
import Header from "./components/Header";
import Categories from "./components/Categories";
import TopRated from "./components/TopRated";
import AllCategories from "./pages/AllCategories";
import NewReview from "./pages/NewReview";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/categories" element={<AllCategories />} />
        <Route path="/newreview" element={<NewReview />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
}


export default App;



