import ReviewForm from "../components/ReviewForm";

const Bewertung = () => {
  return (
    <div>
      <ReviewForm />
    </div>
  );
};

export default Bewertung;
