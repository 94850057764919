import React, { useState, useEffect } from "react";
import { getBusinesses } from "../api";
import "./Hero.css";

const Hero = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async (query) => {
    if (!query) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true); // Ladeanimation starten
    try {
      const response = await getBusinesses();
      const filteredResults = response.data.filter((business) =>
        business.name.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(filteredResults);
    } catch (error) {
      console.error("Fehler beim Abrufen der Suchergebnisse:", error);
    } finally {
      setIsLoading(false); // Ladeanimation stoppen
    }
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSearch(searchQuery);
    }, 300); // Debounce, um unnötige API-Calls zu vermeiden

    return () => clearTimeout(debounceTimer);
  }, [searchQuery]);

  return (
    <div className="hero">
      <div className="hero-content">
        <h1>Finde die besten lokalen Geschäfte in deiner Nähe</h1>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Wonach suchst du?"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && (
            <div className="search-results">
              {isLoading ? (
                <div className="loader"></div>
              ) : searchResults.length > 0 ? (
                <ul>
                  {searchResults.map((business) => (
                    <li key={business._id}>{business.name}</li>
                  ))}
                </ul>
              ) : (
                <p>Keine Ergebnisse gefunden</p>
              )}
            </div>
          )}
        </div>
        <div className="cta-buttons">
          <button>Jetzt bewerten</button>
          <button>Geschäfte durchsuchen</button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
