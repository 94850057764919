import React from "react";
import "./TopRated.css";

const topRatedBusinesses = [
  { name: "Bäckerei Müller", rating: 5, comment: "Fantastische Brötchen!" },
  { name: "Friseur Schmidt", rating: 4.8, comment: "Super Styling und Service!" },
  { name: "Restaurant Toscana", rating: 4.9, comment: "Ein Geschmackserlebnis!" },
];

const TopRated = () => {
  return (
    <div className="top-rated">
      <h2>Top-Bewertungen</h2>
      <div className="top-rated-list">
        {topRatedBusinesses.map((business, index) => (
          <div key={index} className="top-rated-card">
            <h3>{business.name}</h3>
            <p>⭐ {business.rating.toFixed(1)}</p>
            <p>"{business.comment}"</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopRated;
