// src/components/Categories.js
import React from "react";
import "./Categories.css";

const allCategories = [
  { name: "Restaurants", icon: "🥘", description: "Finde die besten Restaurants." },
  { name: "Friseure", icon: "💇", description: "Styling und Pflege." },
  { name: "Shops", icon: "🛍️", description: "Entdecke lokale Geschäfte." },
  { name: "Dienstleistungen", icon: "🛠️", description: "Hilfe und Services." },
  { name: "Fitnessstudios", icon: "🏋️", description: "Gesundheit und Fitness." },
  { name: "Kinos", icon: "🎥", description: "Erlebe die neuesten Filme." },
  // ...weitere Kategorien
];

export const Categories = ({ limit }) => {
  // Limitiere die Anzahl der angezeigten Kategorien (falls limit gesetzt)
  const categoriesToShow = limit ? allCategories.slice(0, limit) : allCategories;

  return (
    <div className="categories">
      <h2>{limit ? "Beliebte Kategorien" : "Alle Kategorien"}</h2>
      <div className="categories-grid">
        {categoriesToShow.map((category, index) => (
          <div key={index} className="category-card">
            <span className="category-icon">{category.icon}</span>
            <h3>{category.name}</h3>
            <p>{category.description}</p>
          </div>
        ))}
      </div>
      {limit && (
        <div className="categories-button">
          <button onClick={() => window.location.href = "/categories"}>
            Alle Kategorien anzeigen
          </button>
        </div>
      )}
    </div>
  );
};


