import React from "react";
import { Link } from "react-router-dom";
import "./Header.css"; // Styling für den Header

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <Link to="/">Vivares</Link>
      </div>
      <nav className="menu">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/categories">Kategorien</Link></li>
          <li><Link to="/newreview">Bewertung schreiben</Link></li>
          <li><Link to="/login">Login</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
