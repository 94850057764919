import axios from "axios";

const API = axios.create({
  baseURL: "https://api.vivares.at",
});

export const getBusinesses = () => API.get("/api/businesses");


export const getNewestReviews = () => API.get("/api/reviews/newest");


export const postReview = (reviewData) => {
  console.log("Review Data:", reviewData); // Debugging
  return API.post("/api/reviews", reviewData);
};


export const searchBusinesses = (query) => {
  return API.get(`/api/businesses/search?query=${query}`);
};
